import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Mempelai, Divider, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert } from 'react-bootstrap'
import { cap } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import music from '../assets/music/kristi.mp3'
import mask from '../assets/img/dinny/mask.svg'
import distance from '../assets/img/dinny/distance.svg'
import salaman from '../assets/img/dinny/salaman.svg'
import logoig from '../assets/img/kristi/logoig.svg'
import burung from '../assets/img/dinny/burung.svg'
import bunga6 from '../assets/img/bunga6.png'
import covid from '../assets/img/kristi/covid.svg'
import AOS from 'aos';
import { gambar } from '../params'
import post from '../params/post'
import getData from '../params/getdata'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment'
import { Helmet } from "react-helmet";

let cmain = '#553E91'
let mainfont = '#553E91'
let black = 'rgb(38,38,38)'
export default class Halo extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.myRef = React.createRef()
    this.nama = React.createRef()
    this.alamat = React.createRef()
    this.pesan = React.createRef()
    this.state = {
      acara: [],
      days: '00',
      minutes: '00',
      hours: '00',
      seconds: '00',
      hide: true,
      hadir: true,
      err: [],
      submitted: '',
      sesi: 2,
      comment: []
    }
  }
  async componentDidMount() {
    AOS.init({
      // initialise with other settings
      duration: 2000
    });
    var countDownDate = new Date("2020", "11", "05", "09").getTime();
    // Update the count down every 1 second
    var x = setInterval(() => {
      // Get today's date and time
      var now = new Date().getTime();
      // Find the distance between now and the count down date
      var distance = countDownDate - now;
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.setState({
        days: days, hours: hours, minutes: minutes, seconds: seconds
      })
    }, 1000);
    try {
      let comment=await getData('kepada:"kristi-putut"')
      this.setState({ comment: comment })
    } catch (e) {
      console.log(e)
    }
  }

  play = () => {
    AOS.refresh()
    var snd = new Audio(music);
    snd.type = 'audio/mp3';
    snd.play();
    this.setState({ hide: false })
    // setTimeout(() => {
    //   var elmnt = document.getElementById('top');
    //   elmnt.scrollIntoView();
    // }, 1000)
  }

  useQuery = () => {
    return new URLSearchParams(this.props.location.search);
  }
  handleSubmit = async () => {
    let err = []
    let local = localStorage.getItem('pesan')
    if (this.nama.current.value == "") {
      err.push('Nama tidak Boleh Kosong')
    }
    if (this.pesan.current.value == "") {
      err.push('Pesan tidak Boleh Kosong')
    }
    if (err.length == 0) {
      confirmAlert({
        message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
        buttons: [
          {
            label: 'Yes',
            onClick: async () => {
              let send = await post(
                ` dari: "${this.nama.current.value}", hadir: "", jumlahhadir: "", kepada: "kristi-putut", pesan:"${this.pesan.current.value}",alamat: ""`
              )
            if (send.status == 200) {
                this.setState({ submitted: true })
                localStorage.setItem('pesan', this.pesan.current.value)
                this.nama.current.value = ''
                this.pesan.current.value = ''
            }else{
                err.push('Koneksi Gagal')
            }
            }
          },
          {
            label: 'No',
            onClick: () => { }
          }
        ]
      });
    } else {
      this.setState({ err: err, submitted: false })
    }


  }
  render() {
    let { hadir, days, hours, minutes, seconds, hide, submitted, err, comment } = this.state
    let slide = ["	https://i.ibb.co/d7gb07H/MMO-3672.jpg	",
      "	https://i.ibb.co/NSDmPyP/MMO-3621.jpg	",
      "	https://i.ibb.co/ccKgy7F/MMO-3537.jpg	",
      "	https://i.ibb.co/ZV6bV4L/MMO-3531.jpg	",
      "	https://i.ibb.co/LJZTL22/MMO-3514.jpg	",
      "	https://i.ibb.co/wBrm8RJ/MMO-3388.jpg	",
      "	https://i.ibb.co/9Z30trz/MMO-3374.jpg	",
      "	https://i.ibb.co/3zCFN9Y/MMO-3249.jpg	",
      "	https://i.ibb.co/Xthw8tn/MMO-3214.jpg	",
      "	https://i.ibb.co/kqQqb05/MMO-3156.jpg	",
    ]
    let slider = []
    slide.map(v => {
      slider.push(gambar(v, 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=720&h=520'))
    })
    let query = this.useQuery().get('name');
    query = query ? cap(query) : ''

    return (
      <>
        {/* <Helm
          title='Undanganku - Kristi & Putut'
          desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
          logo={logo}
          img={slide[0]}
          url='https://undanganku.me/kristi-putut'
        /> */}

        <Helmet>
          <meta property="og:image" content="https://i.ibb.co/3T246Qy/MMO-3374.png" />
          <meta property="og:title" content="Undanganku - Kristi & Putut" />
          <meta property="og:description" content="undangan digital berbasis website untuk berbagai kebutuhan acara" />
        </Helmet>

        <div id='gold5' className="position-relative" style={{
          backgroundImage: `url(${'https://i.ibb.co/gmz96rb/Background-Putih.jpg'})`,
          backgroundSize: 'cover', backgroundPosition: 'center'
        }}>

          {hide ? (
            <Container fluid id='g3-header' className='position-absolute' style={{
              backgroundImage: `url(${gambar('https://i.ibb.co/D9yXwHP/modal1.jpg', 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=1080&h=720')})`, top: 0, left: 0, zIndex: 999
            }}>
              <Item>
                <Col xs={12} md={4} className='m-2 m-md-0 '>
                  <img className='img-fluid w-100 p-2'
                    src={gambar('https://i.ibb.co/m8rkz2W/Logo-Fix-1.png')} data-aos="fade-left" />
                </Col>
              </Item>
              <div style={{ position: 'absolute', bottom: '10%', left: 0, width: '100%' }}>
                <p className="w-100 text-center" style={{ fontSize: '16px' }}>
                  Kepada Yth :<br /> 
                  <b style={{ fontSize: '18px' }}>{query ? query : ''} </b>
                  <br /><br />
              di Tempat<br /><br />
              Kami Mengundang<br />
              Bapak/Ibu/Saudara/i untuk hadir di pernikahan kami
              </p>

                <div className="w-100 text-center">
                  <p style={{ backgroundColor: cmain, borderRadius: '10px', color: 'white' }}
                    className="p-2 mb-0 btn"
                    onClick={() => {
                      this.play()
                    }}>
                    <img src="https://www.flaticon.com/svg/static/icons/svg/3062/3062634.svg" width="30px" />
                    {`  Buka Undangan`}
                  </p>
                </div>
              </div>
            </Container>
          ) : (

              <div className='w-100' style={{
                overflow: 'hidden', maxWidth: '100vw',
                backgroundColor: 'transparent'
              }}>
                <Container fluid id='g3-header' className='position-relative' style={{
                  backgroundImage: `url('https://i.ibb.co/PzWkYLn/modal2.jpg')`
                }}>
                  <div style={{ position: 'absolute', bottom: '10%', width: '100%' }}>
                    <Item>
                      <Col xs={12} md={4} className='m-2 m-md-0 '>
                        <img className='img-fluid w-100 p-2'
                          src={gambar('https://i.ibb.co/YB0H0fH/Logo-Dalam-Fix.png')} />
                      </Col>
                    </Item>
                  </div>
                </Container>

                <div className={hide ? 'd-none' : 'd-block'}>
                  <div id="top" style={{ backgroundColor: 'transparent' }}>

                    {/* <Container id='sectiongold55' className="py-5 dinny" >

                  <Item>
                    <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                      <div className='kotak mr-lg-2'>
                        <Item>
                          <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                            Kristi
                      </h1>
                        </Item>
                        <Item>
                          <Col xs={6}>
                            <img src={gambar('https://i.ibb.co/VTDs5f9/Individual-2.jpg', 90)} className='img-fluid w-100' />
                          </Col>
                        </Item>
                        <Item>
                          <h1 className="py-3" style={{
                            fontSize: '24px', fontFamily: "'Marck Script', cursive",
                            color: mainfont
                          }}>
                            Dwi Kristiani
                      </h1>
                        </Item>
                        <Item>
                          <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                            <b>The Daughter of: </b><br />
                            Alm. Kasiwan <br />
                        &<br />
                        Ibu Tasinem
                      </p>
                        </Item>
                        <Item>
                          <Col xs={3} sm={2}
                            onClick={() => { window.open('https://instagram.com/dw.chriz') }}>
                            <img src={logoig} className='img-fluid w-100 btn' />
                          </Col>
                        </Item>
                      </div>
                    </div>
                    <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                      <div className='kotak mr-lg-2'>
                        <Item>
                          <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                            Putut
                      </h1>
                        </Item>
                        <Item>
                          <Col xs={6}>
                            <img src={gambar('https://i.ibb.co/3CYZTyx/Individual-1.jpg', 90)} className='img-fluid w-100' />
                          </Col>
                        </Item>
                        <Item>
                          <h1 className="py-3" style={{ fontSize: '24px', fontFamily: "'Marck Script', cursive", color: mainfont }}>
                            Putut Agus Setiawan
                      </h1>
                        </Item>
                        <Item>
                          <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                            <b>The Son of : </b><br />
                            Bpk. Lasuwi
                        <br />
                        &<br />
                        Ibu Siti Kuntamah
                      </p>
                        </Item>
                        <Item>
                          <Col xs={3} sm={2}
                            onClick={() => { window.open('https://instagram.com/pastwn') }}>
                            <img src={logoig} className='img-fluid w-100 btn' />
                          </Col>
                        </Item>
                      </div>
                    </div>
                  </Item>
                </Container>
                 */}
                    <Container fluid style={{ backgroundColor: 'white' }}>
                      <Container id='sectiongold55' className="py-5 dinny putut-name">
                        <Item>
                          <Col xs={6} sm={2} className="py-3">
                            <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                          </Col>
                        </Item>
                        <p className='w-100 text-center p-2 px-4 ' style={{ color: mainfont, fontSize: '20px' }}>
                          Assalamu'alaikum Wr. Wb.<br /><br />
                      Dengan memohon rahmat dan ridho Allah SWT, Kami bermaksud menyelenggarakan pernikahan kami
                    </p>
                        <div className='kotak'>
                          <Col xs={12} className="p-0">
                            <Row>
                              <Col xs={4} className="p-0">
                                <img src={gambar('https://i.ibb.co/rwLkxYX/cowok.png', 95)} className="w-100 img-fluid" />
                              </Col>
                              <Col xs={8} className="p-0">
                                <Row className="h-100 pl-3">
                                  <h1 className="my-auto pl-md-3" style={{
                                    fontFamily: "'Marck Script', cursive",
                                    color: 'black'
                                  }}>
                                    Putut Agus Setiawan
                          </h1>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={4} className="p-0 pr-3">
                            <Item>
                              <img src={logoig} width='30px' height='50px' className="btn p-0"
                                onClick={() => { window.open('https://instagram.com/pastwn') }} />
                            </Item>
                          </Col>

                          <Col xs={12} className="p-0">
                            <Item>
                              <h1 className="my-auto pl-md-3 dan" style={{
                                fontFamily: "'Marck Script', cursive",
                                color: 'black'
                              }}>
                                &
                          </h1>
                            </Item>
                          </Col>

                          <Col xs={12} className="p-0">
                            <Row>
                              <Col xs={4} className="p-0 order-2">
                                <img src={gambar('https://i.ibb.co/N3FHnsz/cewek.png', 95)} className="w-100 img-fluid" />
                              </Col>
                              <Col xs={8} className="p-0 order-1">
                                <Row className="h-100 pr-5 pr-md-3">
                                  <h1 className="my-auto pr-md-3 text-right w-100" style={{
                                    fontFamily: "'Marck Script', cursive",
                                    color: 'black'
                                  }}>
                                    Dwi Kristiani
                          </h1>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                          <Row>
                            <Col xs={8}></Col>
                            <Col xs={4} className="p-0 pr-3">
                              <Item>
                                <img src={logoig} width='30px' height='50px' className="btn p-0" onClick={() => { window.open('https://instagram.com/dw.chriz') }} />
                              </Item>
                            </Col>
                          </Row>

                        </div>
                      </Container>

                    </Container>

                    <Container fluid className="text-center px-4 dinny position-relative py-3 bg-image" style={{
                      color: black,
                      backgroundImage: `url('${gambar('https://i.ibb.co/V9R4t3f/Modal.jpg', 95)}')`,
                      backgroundPosition: 'center top',
                      backgroundRepeat: 'no-repeat'
                    }} >
                      <Item>
                        <p className="fs16" style={{ color: 'white' }}>
                          Yang Insyaa Allah akan dilaksanakan pada:
                    </p>
                      </Item>
                      <Item>
                        <p className="fs20" style={{ color: 'white' }}>
                          <b>
                            SABTU <span className="fs36">05</span> DES 2020
                      </b>
                        </p>
                      </Item>
                      <Item>
                        <p className="fs20 col-sm-4" style={{ color: 'white' }}>
                          <b>Akad Nikah </b><br />
                          <span className=" fs16">
                            09.00 WIB - Selesai
                        <br />
                          </span>
                        </p>
                        <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                        </div>
                        <p className="fs20 pt-3 pt-sm-0 col-sm-4" style={{ color: 'white' }}>
                          <b>Tasyakuran</b><br />
                          <span className=" fs16">
                            Sesi 1 : 10.00 - 11.00 WIB
                      </span> <br />
                          <span className=" fs16">
                            Sesi 2: 11.00 - 12.00 WIB
                      </span> <br />
                          <span className=" fs16">
                            Sesi 3: 12.00 - 13.00 WIB
                      </span><br />
                          <div style={{ fontSize: '14px', fontStyle: 'italic' }}>Tamu undangan diharapkan hadir sesuai dengan sesi yang telah di tentukan</div>
                        </p>
                        <p className="w-100 fs16 text-center" style={{ color: 'white' }}>
                          Jln Seto No. 31 RT 03 RW 17, Kel. Gumilir, Kec. Cilacap Utara, Kab. Cilacap, Jawa Tengah 53231
                    </p>
                      </Item>

                      <Item>
                        <Col xs={10} sm={3}
                          style={{
                            border: `2px solid ${cmain}`,
                            borderRadius: '10px',
                            backgroundColor: cmain
                          }}
                          onClick={() => {
                            window.open("https://maps.app.goo.gl/EUhjwwsDsvXztR5W7")
                          }}
                          className="p-2 mx-sm-2 btn">
                          <Item>
                            <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                              style={{ width: "10%", height: '10%' }} />
                            <p className="mb-0 my-auto ml-3" style={{ color: 'white' }}>
                              <b>Get Direction</b>
                            </p>
                          </Item>
                        </Col>
                        <Col
                          onClick={() => window.open('https://calendar.google.com/event?action=TEMPLATE&tmeid=M2sycmJpbHM0ZmQxYWhwNjM5azh0b2UyYTUgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com')}
                          xs={10} sm={3}
                          style={{
                            border: `2px solid ${cmain}`,
                            borderRadius: '10px',
                            backgroundColor: cmain
                          }}
                          className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                          <Item>
                            <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                              style={{ width: "10%", height: '10%' }} />
                            <p className="mb-0 my-auto ml-3" style={{ color: 'white' }}>
                              <b>Add to Calendar</b>
                            </p>
                          </Item>
                        </Col>
                      </Item>
                      <Container id='sectiongold57'>
                        <div className='py-3'>

                          <Item>
                            <div data-aos="fade-left" data-aos-duration="1000"
                              className='col-10 col-lg-8 kotak' style={{ backgroundColor: cmain }}>
                              <Item>
                                <div className='item'>
                                  <Item>
                                    <div>
                                      {days}
                                    </div>
                                  </Item>
                                  <Item>
                                    <span>
                                      Days
                                </span>
                                  </Item>
                                </div>
                                <div className='dot'>:</div>
                                <div className='item'>
                                  <Item>
                                    <div>
                                      {hours}
                                    </div>
                                  </Item>
                                  <Item>
                                    <span>
                                      Hours
                      </span>
                                  </Item>
                                </div>
                                <div className='dot'>:</div>
                                <div className='item'>
                                  <Item>
                                    <div >
                                      {minutes}
                                    </div>
                                  </Item>
                                  <Item>
                                    <span>
                                      Mins
                      </span>
                                  </Item>
                                </div>
                                <div className='dot' >:</div>
                                <div className='item'>
                                  <Item>
                                    <div>
                                      {seconds}
                                    </div>
                                  </Item>
                                  <Item>
                                    <span>
                                      Secs
                      </span>
                                  </Item>
                                </div>
                              </Item>

                            </div>
                          </Item>
                        </div></Container>

                    </Container>

                    {/* <Container className="text-center dinny">
                  <Item>
                    <Col className="py-3 px-sm-5" style={{ border: `2px solid ${cmain}`, borderRadius: '10px' }}>
                      <h1 className="fs30"
                        style={{

                          fontFamily: "'Marck Script', cursive",
                          color: 'mainfont'
                        }}>
                        New Normal Rules
                          </h1>
                      <Item>
                        <Col xs={12}>
                          <p className="cblack text-center w-100 fs16">
                            Demi keamanan dan kenyamanan bersama, para hadirin undangan dihimbau untuk memenuhi beberapa peraturan berikut:
                          </p>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={mask} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              Gunakan Masker
                            </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={distance} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              jaga Jarak
                            </p>
                          </Row>
                        </Col>
                        <Col xs={6} sm={4}>
                          <img src={salaman} className="w-100 img-fluid p-sm-4" />
                          <Row>
                            <p className="cblack text-center w-100 fs16">
                              Cukup Bersalaman tanpa Bersentuhan
                      </p>
                          </Row>
                        </Col>
                      </Item>
                    </Col>
                  </Item>

                  <Item>
                    <Col>
                    </Col>

                  </Item>

                </Container>
 */}

                    <Container className="py-3">
                      <Item>
                        <Col xs={12} md={6}>
                          <img src={covid} className="w-100 img-fluid" />
                        </Col>
                      </Item>
                    </Container>

                    <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                      <h1 className="w-100 text-center allura" style={{ color: cmain }}>
                        Our memories <br />
                    Wedding Galery
                  </h1>

                      {/* <Slider slide={slider} /> */}
                      <Row>
                  {slider.map(v=>{
                    return (
                      <Col xs={12} md={6}>
                        <img src={v} className="w-100 img-fluid"/>
                      </Col>
                    )
                  })}
                  </Row>
                      <Container className="dinny px-3 pt-5 ">
                        <Item>
                          <p className="text-center cblack">
                            <span className="fs16">
                              And one of His signs is that He created for you spouses from
                              among yourselves so that you may find comfort in them. And
                              He has placed between you compassion and mercy. Surely in
                              this are signs for people who reflect. (Ar-Rum :21)
                        </span>
                          </p>
                        </Item>

                      </Container>
                    </Container>

                    <Container id='sectiongold56'>
                      <div className='pt-3'>

                        <div data-aos={`fade-right`} data-aos-duration="2000">
                          <Item>
                            <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                              <Item>
                                <p className='fs16 text-center p-2 px-4'>
                                  “You are every reason, every hope and every dream I’ve ever had.”
                                <br />—Nicholas Sparks-
                              </p>
                              </Item>
                            </div>

                          </Item>
                        </div>
                      </div>
                    </Container>

                    <Container id='sectiongold58' >

                      <Row>
                        <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                          <Item>
                            <Col xs={4} lg={2}>
                              <img data-aos="zoom-in" data-aos-duration="1000" src={bunga6} className='img-fluid w-100' />
                            </Col>
                          </Item>
                          <Item>
                            <div className='col-10 col-md-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                              <Item>
                                <h1 style={{
                                  fontFamily: '"Marck Script", cursive',
                                  color: mainfont
                                }}>
                                  Send Your Wishes
                              </h1>
                              </Item>
                              <Item>
                                <form className="col-12 w-100">
                                  <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama'  />
                                  <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                  {/* <Item>
                              <div id="formradio">
                                <div class="custom_radio row justify-content-center">
                                  <div onClick={() => {
                                    this.setState({ hadir: true })
                                  }
                                  }>
                                    <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                    <label for="featured-1">Hadir</label>
                                  </div>
                                  <div onClick={() => {
                                    this.setState({ hadir: false })
                                  }
                                  } className="pl-5">
                                    <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                    <label for="featured-2"

                                    >Tidak Hadir</label>
                                  </div>
                                 
                                </div>
                              </div>
                            </Item> */}

                                  <Item>
                                    <Col xs={12} className=''>
                                      {
                                        submitted == true ? (
                                          <Alert variant='success' style={{ fontSize: '16px' }}>
                                            Pesan anda sudah disampaikan
                                          </Alert>) : (submitted === false ? (
                                            <Alert variant='danger' style={{ fontSize: '16px' }}>
                                              {
                                                err.map(val => {
                                                  return (
                                                    <li>{val}</li>
                                                  )
                                                })
                                              }

                                            </Alert>
                                          ) : false)
                                      }

                                    </Col>
                                  </Item>
                                  <Item>
                                    <div className='col-6 button rounded btn'
                                      onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                  </Item>
                                </form>
                              </Item>
                            </div>
                            <Col xs={12} md={6}>
                              <div className='col-12 kotak pb-4 pt-4 mt-3 mt-md-0' data-aos="left-left" data-aos-duration="1000" style={{ height: '500px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                {comment.map(v => {
                                  return (
                                    <Item>
                                      <Col xs={2}>
                                      <p style={{ fontSize: '16px' }} className='m-0'>
                                            {v.dari}
                                          </p>
                                      </Col>
                                      <Col xs={10}>
                                        <Alert variant="secondary col-12">
                                          <p style={{ fontSize: '16px' }} className='m-0'>
                                            {v.pesan}
                                          </p>
                                        </Alert>
                                      </Col>
                                    </Item>
                                  )
                                })}
                              </div>
                            </Col>
                          </Item>
                        </div>


                      </Row>
                    </Container>
                    <Container className='text-center mb-5' id='sectiongold59'>
                      <Item>
                        <h1 data-aos="zoom-in" data-aos-duration="1000" style={{ color: mainfont, fontSize: '16px' }}>
                          Possible Wedding
              </h1>
                      </Item>
                      <Item>
                        <h2 data-aos="zoom-in" data-aos-duration="1000" style={{ color: mainfont, fontSize: '20px' }}>
                          Digital Invitation
              </h2>
                      </Item>
                      <Item>
                        <div onClick={() => { window.open('https://instagram.com/possiblewedding') }} >
                          <img src={logoig} width='35px'
                          />
                        </div>
                      </Item>

                    </Container>
                  </div>
                </div>
              </div>
            )}
        </div>

      </>


    )
  }
}

